body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  background-color: #FFAC00 !important;
  background-image: url("././images/logo_Ecopel.svg");
  background-size: initial;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 10px;
  padding-top: 10px;

}
main{
  padding-top: 50px;
}

ul a{
  color: black !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

a{
  color: white !important;
  font-family: 'Poppins', sans-serif !important;
}
span{
  font-family: 'Poppins', sans-serif !important;
}
button{
  color: white !important;
}

.RaList-actions{
  margin-top: 30px;
  margin-bottom: 30px;
}
.MuiButton-containedSizeMedium{
  background-color: #FFAC00 !important;
  color: white;
}

.RaSimpleShowLayout-row button{
  background-color: #FFAC00 !important;
  color: white;
}

.MuiToolbar-regular .MuiButton-textSizeSmall{
  background-color: #FFAC00 !important;
  padding: 8px;
}

.RaDatagrid-tableWrapper td a{
  color:black !important;
}

.RaSimpleShowLayout-stack span a{
  color:black !important;
}

.makeStyles-root-1{
  background-image: radial-gradient(circle at 50% 14em, #FFAC00 0%, #FFAC00 60%, #FFAC00 100%) !important;
}

.makeStyles-root-1 .MuiPaper-root{
 margin-top: 0 !important; 
}

.MuiTablePagination-actions{
  background-color: #FFAC00 !important;
}